<template>
	<div class="Dashboard">
		欢迎进入起搏器病患管理系统
	</div>
</template>
<script>
	export default {
		name: 'Dashboard',
		data() {
			return {};
		},
		mounted() {},
		computed: {},
		watch: {}
	}
</script>
<style>
	.Dashboard{
		font-size:26px;
		color:#606266;
		padding:30px;
	}
</style>
